var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("clicked")
        },
      },
    },
    [
      _c(
        "svg",
        {
          staticClass: "gantt-elastic__task-list-expander-content",
          staticStyle: {
            display: "inline-flex",
            cursor: "pointer",
            margin: "auto 0px",
            "box-sizing": "border-box",
            "user-select": "none",
          },
          attrs: { width: "16", height: "16" },
        },
        [
          _c("rect", {
            staticClass: "gantt-elastic__task-list-expander-border",
            staticStyle: {
              fill: "rgba(255, 255, 255, 0.627)",
              stroke: "rgba(0, 0, 0, 0.627)",
              "stroke-width": "0.5px",
            },
            attrs: {
              x: "0.5",
              y: "0.5",
              width: "15",
              height: "15",
              rx: "2",
              ry: "2",
            },
          }),
          _c("line", {
            staticClass: "gantt-elastic__task-list-expander-line",
            staticStyle: {
              fill: "transparent",
              stroke: "rgb(0, 0, 0)",
              "stroke-width": "1px",
              "stroke-linecap": "round",
            },
            attrs: { x1: "5", y1: "8", x2: "11", y2: "8" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }