<template>
	<span @click="$emit('clicked')">
		<svg
			width="16"
			height="16"
			class="gantt-elastic__task-list-expander-content"
			style="display: inline-flex; cursor: pointer; margin: auto 0px; box-sizing: border-box; user-select: none"
		>
			<rect
				x="0.5"
				y="0.5"
				width="15"
				height="15"
				rx="2"
				ry="2"
				class="gantt-elastic__task-list-expander-border"
				style="fill: rgba(255, 255, 255, 0.627); stroke: rgba(0, 0, 0, 0.627); stroke-width: 0.5px"
			></rect>
			<line
				x1="5"
				y1="8"
				x2="11"
				y2="8"
				class="gantt-elastic__task-list-expander-line"
				style="fill: transparent; stroke: rgb(0, 0, 0); stroke-width: 1px; stroke-linecap: round"
			></line>
			<line
				x1="8"
				y1="5"
				x2="8"
				y2="11"
				class="gantt-elastic__task-list-expander-line"
				style="fill: transparent; stroke: rgb(0, 0, 0); stroke-width: 1px; stroke-linecap: round"
			></line>
		</svg>
	</span>
</template>

<script>
export default {
	name: 'SvgPlus'
};
</script>

<style scoped></style>
